@import '../../variables';

.cst-chart-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;

  > * {
    flex-shrink: 0;
  }

  .cst-chart-header-title,
  .cst-download-button {
    flex-shrink: 0;
  }

  .cst-chart-header-title {
    font-size: 20px;
    color: $color-brand-dark-blue;
    line-height: 42px;
    font-weight: 500;
    min-width: 100px;
    flex-grow: 1;
  }

  .cst-chart-header-controls {
    margin-right: 16px;
    width: 100%;
    flex-shrink: 1;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .cst-switch {
      flex-shrink: 0;
      margin-top: 5px;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: center;

    .cst-chart-header-title {
      margin-bottom: 16px;
      text-align: center;
    }

    .cst-chart-header-controls {
      margin: 0;
    }
  }

  .cst-chart-header-export-wrapper {
    position: relative;

    ul {
      position: absolute;
      top: 36px;
      right: 0;
      z-index: 9999;
      background: #fff;

      li {
        width: 150px;
        padding: 4px 8px;
        border-left: 1px solid #aaa;
        border-right: 1px solid #aaa;
        border-bottom: 1px solid #ccc;

        &:first-child {
          border-top: 1px solid #aaa;
        }

        &:last-child {
          border-bottom: 1px solid #aaa;
        }

        p {
          line-height: 32px;
          font-size: 16px;
          text-align: center;
        }

        &:hover {
          cursor: pointer;
          background: #eee;

          p {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

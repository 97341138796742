@import '../../variables';

.cst-emissions-details-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999999;
  background: rgb(0 0 0 / 50%);

  .cst-emissions-details-modal-wrapper {
    width: 900px;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 64px 77px;
    position: relative;
    border-radius: 8px;

    ul {
      display: flex;
      flex-direction: row;
      margin-bottom: 37px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        display: flex;
        flex-direction: column;
        width: calc(100% - 20px);

        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin-left: 20px;
        }

        &:first-child:last-child {
          margin: 0;
          width: 100%;
        }

        p {
          &:first-child {
            margin-bottom: 8px;
            font-size: 18px;
            line-height: 24px;
          }

          &:last-child {
            background: #fff;
            padding: 20px;
            color: #1d75b8;
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }

    .cst-emissions-details-modal-header {
      position: absolute;
      top: 32px;
      right: 32px;

      .cst-icon-close {
        &:hover {
          cursor: pointer;

          path {
            fill: $color-brand-dark-blue;
          }
        }
      }
    }
  }
}

.cst-page-loader {
  height: 100% !important;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 20px;
  }

  .cst-page-loader-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .cst-page-loader-inner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cst-page-loader-description {
        margin-top: 32px;
      }
    }
  }
}

@import '../../variables';

.cst-emissions-list-header {
  display: flex;
  flex-direction: row;
  margin: 32px 0 0;
  padding: 24px 32px;
  width: calc(100% - 62px);
  border-bottom: 1px solid #e0e0e0;

  .cst-emissions-list-header-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    flex-shrink: 0;
  }

  .cst-emissions-list-header-sub-title {
    text-align: left;
    font-size: 14px;
    color: #9b9b9b;
    font-weight: 400;
    line-height: 19px;
    margin-left: 16px;
    width: 100%;
  }

  .cst-emissions-list-header-controls {
    font-size: 14px;
    line-height: 19px;
    margin-left: 16px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;

    > * {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    li {
      flex-shrink: 0;
    }

    &:last-child {
      text-align: right;
      flex: 1;
      justify-content: right;
    }

    p {
      color: #9b9b9b;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #000;
      }
    }
  }
}

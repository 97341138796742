@import '../../../../variables';

.cst-insights-controls {
  min-width: 506px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .cst-insights-controls-left {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: 32px;

    > * {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .cst-insights-controls-right {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 22px;
  }

  &.vertical {
    min-width: 164px;

    &,
    .cst-insights-controls-right,
    .cst-insights-controls-left-date-pickers {
      flex-direction: column;
    }

    .cst-insights-controls-left-date-pickers {
      margin-bottom: 16px;
    }

    .cst-date-picker {
      margin-bottom: 16px;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      button {
        width: 100%;
      }
    }
  }
}

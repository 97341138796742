@import '../../variables';

.cst-chart-page-controls {
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 16px;
  font-size: 14px;
  line-height: 8px;
  width: 100%;

  > p {
    width: 100%;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    &:first-child:hover:not(.disabled),
    &:last-child:hover:not(.disabled) {
      text-decoration: underline;
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.25;
    }
  }

  .cst-chart-page-controls-page {
    font-weight: 500;
  }
}

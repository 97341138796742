@import '../../variables';

.cst-nav-bar {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: $color-brand-dark-blue;
  width: $navbar-width;
  padding: $navbar-padding;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;

  li {
    margin-bottom: 42px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    opacity: 0.5;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }

    &:first-child {
      margin-top: 21px;
    }

    &:last-child {
      margin-top: auto;
    }

    p {
      color: #eee;
      margin-top: 12px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .cst-icon-settings path,
  .cst-icon-export path {
    stroke: #eee;
  }
}

@import '../../variables';

.cst-sub-warning-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background: #f6f6f6;
  border-bottom: 1px solid $color-brand-dark-blue;

  > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  p {
    font-size: 14px;

    & + p {
      font-weight: bold;
    }

    &:first-child {
      margin-right: 4px;
    }
  }

  button {
    background: #ddd;
    border: none;

    &:hover {
      background: #ccc;
      cursor: pointer;
    }
  }
}

@import '../../variables';

.cst-page-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .cst-login-error {
    color: #f00;
  }

  .logo {
    display: block;
    margin: 0 auto;
    width: 200px;
    margin-bottom: 80px;
  }

  h1 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  form {
    margin: 0 auto;
    width: 400px;

    & > * {
      width: calc(100% - 64px);
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
      margin-bottom: 38px;
      color: #272727;
    }

    label {
      display: inline-block;
      margin-bottom: 8px;
    }

    button {
      width: 100%;
      padding: 16px 32px;
      border-radius: 6px;
      text-align: left;

      &.primary {
        background-color: $color-brand-dark-blue;
        color: #fff;
        text-align: center;
        margin-bottom: 16px;

        &:hover {
          background-color: $color-brand-blue;
        }
      }

      &.secondary {
        background: #fff;
        text-align: end;
        padding: 0;
        width: fit-content;
        display: block;
        margin-left: auto;
        text-decoration: underline;

        &:hover {
          color: $color-brand-gray;
          text-decoration-color: $color-brand-gray;
        }
      }
    }
  }
}

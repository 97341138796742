@import '../../variables';

.cst-page-admin {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  margin-left: calc($navbar-width + 2 * $navbar-padding);

  .cst-fluid-content-wrapper {
    min-height: 100%;
    width: 100%;
  }

  .cst-page-admin-wrapper {
    display: flex;
    flex-direction: column;
  }

  .cst-page-admin-sub-controls {
    label {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 8px;
      display: block;
    }

    input {
      width: 100%;

      &.disabled {
        background: #ccc;
      }
    }
  }

  .cst-page-admin-error {
    font-size: 14px;
    margin-top: 8px;
    color: $color-red;
  }

  .cst-page-admin-input-wrapper {
    display: flex;
    flex-direction: row;

    input {
      margin-right: 16px;
      flex-shrink: 0;
      width: 300px;
    }
  }

  .cst-page-admin-input-wrapper button {
    flex-shrink: 0;
    margin-right: 16px;
  }

  .cst-page-admin-recent-subs {
    margin-top: 32px;

    h4 {
      font-weight: 400;
      margin-bottom: 16px;
    }

    ul {
      li {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        > * {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }

        p {
          font-size: 14px;
          line-height: 32px;
          padding: 0 16px;
          background: #eee;
          width: 300px;

          &:hover {
            cursor: pointer;
            background: #ddd;
          }

          &:last-child {
            color: $color-brand-dark-blue;
            width: auto;
            background: #fff;
            border: none;
            opacity: 1;
            font-weight: bold;
          }
        }

        &.active p {
          pointer-events: none;
          border: 1px solid $color-brand-dark-blue;
          background: #fff;
        }

        input {
          width: 200px;
        }
      }
    }
  }

  .cst-page-admin-recent-subs ul li button {
    background: #fff;
    border: 1px solid #ccc;
    color: #333;
    opacity: 0.75;
  }

  .cst-page-admin-input-wrapper button:last-child {
    margin-right: 0;
  }

  .cst-page-admin-input-wrapper button:disabled {
    opacity: 0.5;
  }

  .cst-page-admin-recent-subs ul li button:hover {
    color: #000;
    opacity: 1;
    background: #eee;
    cursor: pointer;
  }

  .cst-page-admin-recent-subs ul li button:disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  .cst-page-admin-recent-subs ul li button:first-child {
    background: #eee;
  }

  .cst-page-admin-input-wrapper button:disabled:hover {
    cursor: default;
  }

  .cst-page-admin-recent-subs ul li button:first-child:hover {
    background: #ddd;
  }
}

@import '../../variables';

.cst-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cst-switch-inner {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;

  p {
    font-size: 14px;
    line-height: 32px;
    color: #333;
  }

  > p:first-child {
    margin-right: 12px;
  }

  > p:last-child {
    margin-left: 12px;
  }

  > div {
    margin-top: 8px;
  }

  .react-switch-bg {
    background: #666 !important;
  }
}

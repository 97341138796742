@import '../../variables';

.cst-page-insights {
  display: flex;
  flex-direction: row;
  background: #f4f4f4;
  position: relative;
  height: 100%;

  .cst-map {
    width: 100%;
    height: 100%;
  }

  &.withSubWarning {
    padding-top: 32px;

    .cst-map {
      top: 32px;
    }
  }

  .cst-fluid-content-wrapper {
    background: $color-bg-sidebar;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    min-width: 500px;

    .cst-fluid-content-wrapper-content {
      .cst-net-emissions-chart,
      .cst-multiple-emissions-chart {
        margin-bottom: 64px;
      }
    }

    &.loading .cst-fluid-content-wrapper-content {
      height: 100%;
    }
  }

  .cst-page-insights-map-content-wrapper-outer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 600px);
    position: relative;
    padding-top: 179px;
    padding-left: 598px;

    .cst-fluid-content-wrapper {
      flex-shrink: 1;
      width: auto;
      padding-bottom: 0;
    }
  }

  .cst-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999999;
  }

  .cst-baseline-chart-stats {
    flex-direction: column;
    align-items: end;

    .cst-baseline-chart-stats-item {
      margin-right: 0;
      margin-bottom: 32px;
      width: 60%;

      &:last-child {
        margin-bottom: 0;
      }

      .cst-baseline-chart-stats-item-value {
        width: 200px;
        height: 32px;
        flex-shrink: 0;
      }
    }
  }

  .cst-page-insights-content-wrapper-outer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 679px);
    position: relative;
    padding-top: 148px;
    padding-left: 612px;

    .cst-fluid-content-wrapper {
      flex-shrink: 1;
      width: auto;
      padding-top: 32px;
      padding-bottom: 0;
      margin-top: 16px;
    }

    .cst-no-data-skeleton {
      height: 100%;
      width: calc(100% + 65px);
    }
  }

  .cst-page-insights-graph-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    > * {
      width: calc(50% - 32px);
      margin: 0 16px;
    }
  }

  .cst-chart-header-title {
    color: $color-brand-dark-blue;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 32px;
  }

  .cst-chart-placeholder {
    height: 200px;
    width: 100%;
    background: #fff;
    color: $color-brand-dark-blue;
    font-size: 18px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 64px;
  }
}

@media (width <= 1400px) {
  .cst-page-insights .cst-page-insights-content-wrapper-outer {
    width: calc(100% - 678px);
  }
}

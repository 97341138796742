@import '../../variables';

.cst-baseline-chart {
  display: flex;
  flex-direction: row;
}

.cst-baseline-chart-title {
  color: $color-brand-dark-blue;
  font-size: 20px;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 32px;
}

.cst-baseline-chart-left {
  width: 344px;
  display: flex;
  flex-direction: column;
  padding: 8px 32px 8px 8px;
  flex-shrink: 0;
  height: 440px;
}

.cst-baseline-chart-left-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.cst-baseline-chart-controls {
  display: flex;
  flex-direction: row;
  padding-bottom: 32px;
}

.cst-baseline-chart-controls-left {
  display: grid;
  grid-column-gap: 3rem;
  grid-template-columns: repeat(4, auto);
  width: 100%;
}

.cst-baseline-chart-left-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cst-baseline-chart-left-section-header {
  margin-bottom: 8px;
  font-weight: 400;
}

.cst-baseline-chart-left-section-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;
}

.cst-baseline-chart-stats {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
}

.cst-baseline-chart-stats-item {
  display: flex;
  flex-direction: row;
  width: calc(100% - 16px);
  margin-right: 16px;

  &:last-child {
    width: 100%;
    margin-right: 0;
  }
}

.cst-baseline-chart-stats-item-value {
  display: flex-column;
  align-content: center;
  margin-left: 32px;
  border-radius: 2rem;
  padding: 16px;
  width: 300px;
  background-color: $color-green;

  &.red {
    background-color: $color-red;
  }

  p {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
}

.cst-baseline-chart-right {
  width: 100%;

  .cst-dropdown + p {
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .cst-switch {
    p {
      font-size: 16px;
    }
  }
}

.cst-baseline-chart-stats-item-labels {
  display: flex;
  flex-direction: column;
  text-align: right;
  flex-grow: 1;
  font-size: 16px;
  line-height: 1.5;

  > *:last-child {
    color: #555;
    font-size: 16px;
  }

  > *:first-child {
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
  }
}

.cst-baseline-chart-placeholder {
  height: 200px;
  width: 100%;
  background: #fff;
  color: $color-brand-dark-blue;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
}

@import '../../variables';

.cst-download-button {
  font-size: 16 px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 3px 22px;
  background: #ccc;
  text-wrap: nowrap;

  p {
    font-size: 16px;
    line-height: 32px;
  }

  .cst-icon {
    margin-top: 6px;
    margin-right: 8px;
  }

  &:hover {
    cursor: pointer;
    background: #ddd;
  }
}

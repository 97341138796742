@import '../../variables';

.cst-group-list-item {
  padding: 20px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 1px 1px #ddd;
  border: 1px solid transparent;
  height: 193px;
  position: relative;

  &.expanded {
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &.selected {
    border: 1px solid #003b8a;
  }

  .cst-group-list-item-selection-index {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: $color-brand-dark-blue;
    color: #fff;
    border-radius: 50%;
    text-align: center;
  }

  .cst-group-list-item-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    padding: 0 32px;

    h3 {
      font-size: 14px;
      line-height: 18px;
      font-weight: 450;
      color: #000;
      margin-right: 16px;
      flex-shrink: 0;
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 450;
      color: #9b9b9b;
    }

    h3 + p {
      width: 100%;
    }
  }

  .cst-group-list-item-values {
    padding: 0 32px;

    li {
      padding: 14px;
      background: #f4f4f4;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;

      p {
        font-size: 12px;
        font-weight: 450;
        line-height: 16px;
      }
    }

    p.positive {
      color: $color-text-warning;
    }

    p.negative {
      color: $color-text-success;
    }

    .cst-feature-list-item {
      margin-bottom: 16px;
    }
  }

  .cst-group-list-item-header p:last-child {
    margin-left: 8px;
    color: #ccc;

    &.selected {
      color: #333;
    }
  }

  .cst-group-list-item-list li p:last-child,
  .cst-group-list-item-values li p:last-child {
    flex-shrink: 0;
  }

  .cst-group-list-item-list li p:first-child,
  .cst-group-list-item-values li p:first-child {
    font-weight: 600;
    color: $color-brand-dark-blue;
    width: 100%;
  }

  .cst-group-list-item-header p:last-child:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #333;
  }

  .cst-group-list-item-list {
    padding-right: 16px;
  }
}

@import '../../variables';

.cst-dashboard-cards {
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (width >= 800px) and (width <= 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (width <= 800px) {
    grid-template-columns: 1fr;
  }
}

@import '../../variables';

.cst-year-picker {
  min-width: auto;
  position: relative;

  button {
    width: 100%;
    background: #f9f9f9;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    p {
      line-height: 20px;
      font-size: 16px;
    }

    .cst-icon {
      margin-right: 12px;
    }
  }

  .cst-year-picker-header {
    margin-bottom: 12px;
    color: #333;
    font-size: 16px;
  }

  .cst-year-picker-content-wrapper {
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    padding: 8px 0 24px;
    position: absolute;
    top: 69px;
    left: 0;
    background: #fff;

    .cst-year-picker-nav {
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 12px;
      text-align: center;
      color: #999;

      &:hover:not(.disabled) {
        cursor: pointer;
        color: #000;
      }

      &.disabled {
        opacity: 0;
      }
    }

    .cst-year-picker-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-evenly;

      ul {
        display: flex;
        flex-direction: row;

        li {
          padding: 8px;

          p {
            opacity: 0.75;
          }

          &:hover {
            background: #eee;
            text-decoration: underline;
            cursor: pointer;

            p {
              opacity: 1;
            }
          }

          &.selected {
            background: $color-brand-dark-blue;

            p {
              opacity: 1;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@import '../../variables';

.cst-dropdown {
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }

  button.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    background: #eee;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background: #fff;
    border: 1px solid $color-border-field;
    border-radius: 4px;
    width: 100%;

    p {
      width: 100%;
      margin-right: 16px;
      text-align: left;
      color: #000;
    }

    .cst-icon svg {
      width: 13px;
    }
  }

  &.inline button {
    background: #f4f4f4;
    color: #a0a0a0;

    &.open {
      background: #eee;
    }
  }

  button:active,
  button:hover:not(.open) {
    outline: 1px solid $color-border-active;
  }

  &:not(.disabled, .inline):hover button:not(.open) {
    cursor: pointer;
    border: 1px solid #999;
    background: #fefefe;
  }

  ul {
    position: absolute;
    top: 38px;
    left: 0;
    min-width: 200px;
    background: #fefefe;
    border: 1px solid #b8b8b8;
    max-height: 250px;
    width: calc(100% - 1px);
    overflow-y: scroll;
    z-index: 999999;

    li {
      padding: 6px 16px;
      border-bottom: 1px solid #eee;

      p {
        font-size: 12px;
        color: #000;
      }

      &:nth-child(2n) {
        background: rgb(0 0 128 / 4%);
      }

      &:last-child {
        border-bottom: 0;
      }

      &.selected {
        background: rgb(0 0 200 / 20%);
      }
    }
  }

  &.withLabel ul {
    top: 64px;
  }

  .cst-dropdown-label {
    margin-bottom: 8px;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .cst-dropdown-help {
    margin-top: 8px;
    max-width: 300px;

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: #666;
    }
  }

  ul li:hover:not(.selected) {
    cursor: pointer;
    background: rgb(0 0 0 / 3%);

    p {
      color: $color-bg-active;
      text-decoration: underline;
    }
  }
}

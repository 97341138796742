@import '../../variables';

.cst-no-data-skeleton {
  width: 100%;
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #eee;

  p {
    font-size: 20px;
    color: #333;
  }

  .cst-no-data-skeleton-description {
    margin-top: 32px;
    font-size: 16px;
    color: #000;
  }
}

@import '../../../variables';

.cst-dashboard-card {
  padding: 16px 30px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  text-align: center;

  .cst-dashboard-card-title {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: $color-text-primary;
  }

  .cst-dashboard-card-value {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: $color-text-primary;

    &.red {
      color: $color-text-warning;
    }

    &.green {
      color: $color-text-success;
    }
  }

  .has-tooltip {
    text-decoration: dotted underline;
    cursor: pointer;
  }

  .tooltip {
    max-width: 300px;
    z-index: 2;
  }
}

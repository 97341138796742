@import '../../variables';

.cst-map-emissions-marker {
  position: absolute;
  top: 16px;
  left: -116px;
  width: 200px;

  // &::after {
  //   content: '';
  //   width: 16px;
  //   height: 16px;
  //   transform: rotate(45deg);
  //   background: #fff;
  //   position: absolute;
  //   border: 1px solid #ccc;
  //   z-index: -1;
  //   top: -8px;
  //   left: calc(50% - 8px);
  //   opacity: 0;
  //   animation: appear 0s forwards 1.3s;
  // }

  // @keyframes appear {
  //   to {
  //     opacity: 1;
  //   }
  // }

  .cst-map-emissions-marker-selection-index {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: $color-brand-dark-blue;
    color: #fff;
    border-radius: 50%;
    text-align: center;
  }

  .cst-map-emissions-marker-wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    background: #fff;
    border: 1px solid #ccc;

    ul {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        &:first-child,
        &:last-child {
          width: calc(50% - 16px);
        }

        &:first-child {
          margin-right: 16px;
        }

        &:last-child {
          margin-left: 16px;
        }

        &:first-child:last-child {
          width: 100%;
          margin: 0;
        }

        display: flex;
        flex-direction: column;

        p {
          font-size: 12px;
        }

        p:first-child {
          color: $color-brand-dark-blue;
        }

        p:last-child {
          color: #000;
          text-overflow: ellipsis;
          overflow: hidden;
          max-height: 20px;

          &.positive {
            color: $color-text-warning;
          }

          &.negative {
            color: $color-text-success;
          }
        }
      }
    }
  }
}

.hideMarkers .cst-map-emissions-marker {
  .cst-map-emissions-marker-wrapper {
    display: none;
  }

  .cst-map-emissions-marker-selection-index {
    right: 64px;
  }
}

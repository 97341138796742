@import '../../variables';

.cst-fixed-content-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
  width: calc(100% - 108px);
  background: #fff;
  border-bottom: 1px solid #dbdbdb;
}

@import '../../variables';

.cst-fluid-content-wrapper {
  background: #fff;
  padding: 48px;

  &.centered {
    max-width: 100rem;
    margin: 2rem auto;
    padding: 0 1rem;
  }

  .cst-fluid-content-wrapper-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: $color-brand-dark-blue;
    margin-bottom: 32px;
  }

  .cst-fluid-content-wrapper-content {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 48px;
      padding-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

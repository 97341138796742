@import '../../variables';

.cst-line-chart {
  svg {
    overflow: visible;
    outline: none;

    circle {
      opacity: 0.25;

      &.highlight,
      &:hover {
        opacity: 1;
      }

      &:hover {
        cursor: pointer;

        // NOTE: This is a known property in SVG 2, unrecognized by linter
        r: 5px;
      }

      &.legend {
        opacity: 0.75;
      }
    }

    path {
      pointer-events: none;
    }
  }

  &.hovering {
    svg {
      .cst-line-chart-path-element {
        &:not(.hovered) {
          opacity: 0.02;
        }

        &.hovered {
          opacity: 1;
        }
      }
    }
  }

  .cst-line-chart-wrapper {
    padding: 0 32px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eee;
    box-shadow: 0 0 8px 1px rgb(0 0 0 / 5%);
  }

  .cst-line-chart-rect-red {
    fill: url('#cst_chart_line_gradient_red');
    opacity: 0.5;
  }

  .cst-line-chart-rect-green {
    fill: url('#cst_chart_line_gradient_green');
    opacity: 0.5;
  }
}

@import '../../variables';

.cst-no-data-skeleton-page {
  .cst-no-data-skeleton-page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cst-no-data-skeleton {
      height: 100%;
    }
  }
}

@import '../../variables';

.cst-component-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 32px);

  .cst-component-loader-inner {
    margin: 0 auto;
  }
}

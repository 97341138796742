@import '../../variables';

.cst-bar-chart {
  svg {
    overflow: visible;
    outline: none;
  }

  .cst-bar-chart-wrapper {
    padding: 0 32px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;

    rect {
      &.green {
        fill: $color-green;
      }

      &.red {
        fill: $color-red;
      }
    }
  }

  .cst-bar-chart-title {
    font-size: 16px;
    color: $color-text-primary;
    line-height: 19px;
    font-weight: 500;
    padding: 16px;
  }
}

@import '../../variables';

.cst-chart-axis-left {
  path,
  line {
    display: none;
  }

  text {
    font-size: 13px;
  }
}

.cst-chart-axis-left-label {
  font-weight: bold;
  font-size: 16px;
}

@import '../../variables';

.cst-group-list {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 16px 0;

  .cst-group-list-inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    > li {
      width: 100%;

      &:first-child {
        margin: 0 16px 0 0;
      }

      &:last-child {
        margin: 0 0 0 16px;
      }
    }
  }

  .cst-group-list-inner > li:first-child:last-child {
    margin: 0;
  }

  .cst-group-list-title {
    display: flex;
    flex-direction: row;
    margin: 16px 0;

    > p:first-child {
      font-size: 14px;
      font-weight: 500;
      width: 100%;
    }
  }
}

@import '../../variables';

.cst-search-input {
  position: relative;

  .cst-icon-search {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  input {
    width: calc(100% - 40px);
    background: #fff;
    border: 1px solid $color-border-field;
    border-radius: 4px;
    padding: 12px 0 12px 40px;
    outline: none;

    &:hover {
      outline: 1px solid $color-border-active;
    }
  }

  p:last-child {
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 14px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

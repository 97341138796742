@import './variables';

#offscreen-root {
  position: absolute;
  top: 99999999px;
  right: 99999999px;
}

html,
body,
#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body * {
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
}

ul,
ol {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.cds--header__nav::before {
  display: none;
}

.cds--checkbox-label::before {
  margin-top: 10px;
}

.cds--checkbox-label::after {
  margin-top: 5px;
}

.cds--checkbox-label-text {
  padding-left: 32px;
}

.cds--checkbox-wrapper .cds--form__helper-text {
  margin-left: 51px;
}

.cds--cc--legend {
  display: none !important;
}

.cst-polarized-chart-colors {
  .cds--cc--chart-wrapper path.bar {
    fill: $color-red;

    &[aria-label^='-'] {
      fill: $color-green;
    }
  }

  .cds--cc--legend .checkbox {
    background: $color-green !important;
  }

  .cds--cc--tooltip .datapoint-tooltip .tooltip-color {
    border-top: 12px solid $color-green;
    border-bottom: 12px solid $color-green;
  }
}

.cst--list-box__field {
  display: flex;
  flex-direction: row;
  width: 100%;

  .cst--list-box__label {
    width: 100%;
    font-size: 14px;
    padding: 8px 0;
    text-align: right;
    line-height: 16px;
    margin-right: 8px;
  }

  .cst--list-box__menu-icon {
    margin-top: 8px;
  }
}

.cds--list-box__menu {
  margin-left: -8px;
  max-height: 300px;
  min-width: 128px;
  position: absolute;
  background: #fff;
  border-right: none;
  z-index: 999;

  .cds--list-box__menu-item__option {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 0;
    border-top: none;

    svg {
      margin-left: 8px;
    }
  }

  .cds--list-box__menu-item {
    padding: 8px;
    height: 32px;
    border-bottom: 1px solid $color-border;

    &:first-child {
      border-top: 1px solid $color-border;
    }

    &:hover {
      border-bottom: 1px solid $blue;
      cursor: pointer;
    }
  }
}

button {
  background: $color-bg-inactive;
  padding: 8px 16px;
  border: none;

  &.primary {
    background: $color-bg-active;
    font-weight: bold;
    color: #fff;
  }

  color: #111;

  &:hover {
    cursor: pointer;
  }
}

.cds--chart-holder {
  button {
    background: #fff;
    border: none;

    &:hover {
      cursor: pointer;

      svg {
        fill: $blue;
      }
    }
  }
}

.cds--text-input__label-wrapper {
  margin-bottom: 8px;
}

input,
textarea {
  border: 1px solid #bebebe;
  color: #111;
  padding: 8px 16px;

  &:active,
  &:autofill {
    border: 1px solid $color-border-active;
    background-color: #fff !important;
    outline: none;
  }

  font-size: 14px;
}

.cds--form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cds--form-item {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      width: 100%;
    }
  }
}

.cds--multi-select__wrapper {
  .cds--tag__close-icon {
    margin-top: 2px;
  }

  .cds--tag.cds--tag--filter {
    display: inherit;
    margin-right: 8px;
    background: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 25%;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}

.cds--header {
  border-bottom: none;
}

.react-switch-bg {
  > div {
    display: none;
  }
}

@import '../../variables';

.cst-icon-arrow-up-right {
  &.active {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

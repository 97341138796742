@import '../../variables';

.cst-emissions-filter-header {
  display: flex;
  flex-direction: row;

  .cst-analytics-side-bar-header {
    width: 439px;
    padding: 32px;
    flex-shrink: 0;
    background: #f6f6f6;
    border-right: 1px solid #e9e9e9;

    .cst-analytics-side-bar-header-grouping {
      display: flex;
      flex-direction: row;
      margin: 32px 0 0;

      > * {
        &:first-child {
          width: calc(50% - 16px);
          margin: 0 16px 0 0;
        }

        &:last-child {
          width: calc(50% - 16px);
          margin: 0 0 0 16px;
        }

        &:first-child:last-child {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .cst-emissions-filter-header-content-header {
    padding: 32px;
    width: calc(100% - 64px);

    h2 {
      margin-bottom: 16px;
    }
  }
}

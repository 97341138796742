@import '../../../../variables';

.cst-insights-side-bar {
  position: fixed;
  top: 180px;
  left: 100px;
  z-index: 99999;
  width: 507px;
  height: 100%;
  padding: 0 0 0 4px;
  background: $color-bg-content;
  border-right: 1px solid #e9e9e9;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  .cst-no-data-skeleton {
    height: 100%;
  }

  .cst-emissions-list-header {
    margin-top: 0;
  }

  .cst-feature-list-scrollbars-wrapper,
  .cst-group-list-scrollbars-wrapper {
    padding: 32px 26px;
  }

  .cst-dropdown {
    button {
      background: #fff;

      p {
        color: $color-text-primary;
        font-weight: 600;
      }
    }
  }

  &.grouped .cst-group-list {
    .cst-group-list-inner {
      flex-direction: column;

      > li {
        width: 100%;
        margin: 0 0 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

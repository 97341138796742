@import '../../variables';

.cst-feature-list {
  border-radius: 8px;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

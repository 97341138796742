@import '../../variables';

.cst-chart-legend-text {
  font-size: 12px;
  font-family: monospace;

  &:hover {
    cursor: pointer;
  }
}

@import '../../variables';

.cst-icon-export {
  &.active {
    opacity: 1;
  }

  svg {
    transform: rotate(180deg);
  }
}

$navbar-height: 64px;
$navbar-width: 66px;
$navbar-padding: 20px;
$filter-bar-height: 94px;
$sidebar-width: 600px;
$modal-margin: 128px;
$blue: #00a0ff;
$dark-blue: #0079c1;
$gray: #bebebf;
$dark-gray: #999;
$light-gray: #ccc;
$lightest-gray: #eee;
$color-green: #007a3d;
$color-red: #b22222;
$white: #fff;
$z-index-top: 999999999;
$color-border: $light-gray;
$color-border-active: $blue;
$color-border-light: $lightest-gray;
$color-border-field: #ddd;
$color-bg-active: $blue;
$color-bg-inactive: $light-gray;
$color-brand-dark-blue: #040444;
$color-brand-blue: #003b8a;
$color-brand-border-active: $color-brand-blue;
$color-brand-light-blue: #09ffff;
$color-brand-gray: #494949;
$color-brand-header-gray: $color-brand-gray;
$color-brand-border-inactive: #b5b5b5;
$color-bg-content: #f6f6f6;
$color-bg-content-card: #fcfcfc;
$color-bg-sidebar: #fff;
$color-text-primary: #00012b;
$color-text-success: $color-green;
$color-text-warning: $color-red;

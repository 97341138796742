@import '../../variables';

.cst-feature-list-item-area {
  padding: 0 4px;
  text-align: center;
  font-size: 11px !important;
  min-width: 64px;
}

.cst-feature-list-item {
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 4px;
  padding: 16px 4px;
  justify-content: space-evenly;

  &.selected {
    cursor: pointer;
    background: #f8f8f8;
    border: 1px solid #85a1c5;
    box-shadow: 0 0 11px -6px #85a1c5;

    .cst-feature-list-item-area {
      border-color: #85a1c5;
    }
  }

  &:not(.selected, .clickDisabled):hover {
    border: 1px solid $color-brand-dark-blue;
    cursor: pointer;
  }

  p {
    line-height: 30px;
    font-size: 14px;
  }

  .cst-feature-list-item-name {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    font-family: monospace;
  }

  .cst-feature-list-item-selection-index {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: $color-brand-dark-blue;
    color: #fff;
    border-radius: 50%;
    text-align: center;
  }

  .cst-feature-list-item-name-row p {
    font-size: 12px;
  }

  .cst-feature-list-item-column-relative p,
  .cst-feature-list-item-column p {
    line-height: 1;

    &:first-child {
      font-size: 12px;
      color: #969bb0;
      margin-bottom: 8px;
    }

    &:last-child {
      font-size: 14px;
    }
  }

  .cst-feature-list-item-name-row {
    display: flex;
    flex-direction: row;
    margin-top: 4px;

    > p:first-child {
      flex-shrink: 1;
      color: #969bb0;
      margin: 0 8px 6px 16px;
    }

    > p:last-child {
      margin: 0 16px 0 0;
    }
  }

  > * {
    height: 30px;
    width: 100%;
    flex-shrink: 1;
  }

  .cst-feature-list-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cst-feature-list-item-column-relative {
    min-width: 70px;
  }

  .cst-feature-list-item-column-relative,
  .cst-feature-list-item-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    margin-right: 12px;

    &.positive p:last-child {
      color: $color-text-warning;
    }

    &.negative p:last-child {
      color: $color-text-success;
    }
  }

  .cst-icon-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cst-feature-list-item-column {
    &:first-child {
      padding-left: 16px;
      text-align: start;
    }

    &:last-child {
      text-align: end;
      padding-right: 16px;
      margin-right: 0;
    }

    &.icon {
      opacity: 0.5;

      .cst-icon-info {
        transform: scale(0.9);
      }

      &:hover {
        cursor: pointer;
        opacity: 1;

        .cst-icon-info path {
          fill: $color-brand-dark-blue;
        }
      }
    }

    &.name {
      flex: 1;
    }
  }

  .cst-feature-list-item-column-relative:last-child {
    margin-right: 0;
  }

  .cst-feature-list-item-icons {
    display: flex;
    flex-direction: row;

    > * {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
